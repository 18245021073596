<template>
  <div class="ActivityPreview">
    <div v-loading="loading"></div>
    <swiper :banners="banners"></swiper>
    
    <div class="ActivityPreview-content">
      <div class="crumbs">
        <breadcrumb :refer="refer"></breadcrumb>
      </div>
      <div class="worksList">
        <div v-if="IsData" class="noData">
          <img src="@/assets/PC/profile/listNone.png" alt="" />
        </div>
        <div v-else class="works-item" v-for="(item, index) in works.data" :key="item.id" :class="clientWidth > 750 ? 'flex' : ''">
          <div class="works-item-l">
            <div class="works-Twitter" @click="goWorksDetail(item.id)">
              <img class="cur" v-if="item.imgUrl" :src="item.imgUrl" alt="" width="100%">
              <img class="cur" v-else src="../../assets/PC/keenMind/list0.jpg" alt="" width="100%">
            </div>
          </div>
          <div class="works-item-r">
            <div>
              <div class="mb10" :class="clientWidth > 750 ? 'flex3' : ''">
                <div class="hidden works-item-title cur" @click="goWorksDetail(item.id)" :title="item.title"> {{ item.title }} </div>
                <div v-if="Details.classify != 3">
                  <el-button v-if="!item.isVote" size="mini" type="info" @click="vote(item.id, index)">投票</el-button>
                  <el-button v-else size="mini" type="warning" disabled>已投票</el-button>
                </div>
              </div>
              <div class="works-item-introduction cur" @click="goWorksDetail(item.id)"> {{ item.introduction }} </div>
            </div>
            <div>
              <div class="works-item-operation flex2">
                <div class="glance flex2" title="浏览量">
                  <van-icon name="eye" />
                  <span class="ml"> {{ item.browseCount }} </span>
                </div>
                <div class="collect cur flex2" title="收藏">
                  <img v-if="!item.iCollection" src="@/assets/PC/studyF/study_type_xk.png" @click="collect(item.id, item.iCollection, index)"/>
                  <img v-else src="@/assets/PC/studyF/study_type_xs.png" class="animate__animated animate__fadeIn" @click="collect(item.id, item.iCollection, index)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="works.data.length > 0" class="Page">
        <div v-if="clientWidth > 750" class="page tr">
          <el-pagination
            @current-change="handleCurrentChange"
            :total="works.recordCount"
            :page-size="works.pageSize"
            :current-page="1"
            background
            layout="pager, next"
            next-text="下一页"
            hide-on-single-page
          >
          </el-pagination>
        </div>
        <div v-else class="Mb-Page loadList flex0">
          <div v-if="!IsMobileList" class="loadList0" @click="loadlist">加载更多</div>
          <div v-if="clientWidth < 750 && IsMobileList && works.data.length > 0" class="tc notDetail">
          <div>
            没有更多内容了~ 
            <span class="violet" @click="scrollTop">返回顶部</span>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div v-if="voteSuccess" class="shade flex0">
      <div class="shade-content">
        <img :src="alertContent.bgImg" alt="" width="100%">
        <div class="shade-body flex0">
          <div class="shade-text tc" v-html="alertContent.text"></div>
          <div class="shade-btn">
            <el-button type="info" @click="operation(alertContent.type)">{{ alertContent.buttonText }}</el-button>
          </div>
        </div>
        <i v-if="alertContent.buttonText != '抽奖'" class="el-icon-circle-close co-fff cur shade-body-close" @click="operation('cancel')"></i>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "@/components/Swiper.vue";
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { GetBanner } from '@/api/Home.js';
import { debounce, scrollTop,GetWxShares } from '@/utils/common.js';
import { GetActityShow, ActityShowCollect, UnActityShowCollect, VoteActityShow, GetPrize, GetActivityDetail } from "@/api/KeenMind.js";

export default {
  name: 'ActivityPreview',
  data() {
    return {
      loading: true,
      banners: [], // !banner图
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
        { name: "作品展示", href: "" }
      ],
      worksParam: {
        page: 1,
        limit: 4,
        actityId: this.$route.query.id ? this.$route.query.id : '',
      },
      works: {
        data: [],
        pageCount: 0,
        pageIndex: 1,
        pageSize: 4,
        recordCount: 0,
      }, // !作品列表
      IsData: false,  // !是否有数据
      IsMobileList: false, // !移动端加载更多
      voteSuccess: false, // !弹窗展示
      alertContent: {}, // !弹窗内容
      actityShowId: '',
      Details: {} // !获取活动状态
    }
  },
  mounted() {
    const share = {
      title: '剑鸣 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    // !获取banner图
    GetBanner().then( res => {
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth > 750){
        this.banners = res.data.activity;
      }else{
        this.banners = res.data.activity_m;
      }
    });

    this.GetInit();
  },
  methods: {
    GetInit(Mobile) {
      this.loading = true;
      GetActityShow(this.worksParam).then(res => {
        if(res.status === 1) {
          if(Mobile) {
            this.works.data = this.works.data.concat(res.data)
          } else {
            this.works = res
          }

          if(this.works.data.length > 0) {
            this.IsData = false
          } else {
            this.IsData = true
          }

          if(this.works.data.length >= this.works.recordCount) {
            this.IsMobileList = true
          }
        } else {
          this.$toast(res.message);
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$toast(err);
      })

      const Id = { id: this.$route.query.id }
      GetActivityDetail(Id).then(res => {
        if(res.status == 1) {
          this.Details = res.data;
        }
      })
    },
    // !收藏状态
    collect(id, isCollect, index) {
      if(!this.$store.state.token) {
        this.$toast("请登录后操作");
        return false;
      }
      debounce(()=> {
        if(isCollect) {
          UnActityShowCollect({activityShowId: id}).then(res => {
            this.$toast(res.message)
            if(res.status == 1) {
              this.works.data[index].iCollection = false;
            }
          })
        } else {
          ActityShowCollect({activityShowId: id}).then(res => {
            this.$toast(res.message)
            if(res.status == 1) {
              this.works.data[index].iCollection = true;
            }
          }).catch(err => {
            this.$toast(err)
          })
        } 
      }, 1000)
    },
    // !PC分页
    handleCurrentChange(currentIndex) {
      this.worksParam.page = currentIndex
      this.GetInit();
    },
    // !移动端加载更多
    loadlist() {
      this.worksParam.page++;
      this.GetInit(true);
    },
    // !投票
    vote(id, index) {
      if(!this.$store.state.token) {
        this.$toast("请登录后操作");
        return false;
      }
      this.actityShowId = id
      VoteActityShow({actityShowId: id}).then(res => {
        if (res.status === 1) {
          this.works.data[index].isVote = true;
          this.alertContent = {
            bgImg: require("@/assets/PC/keenMind/vote.png"),
            text: "感谢您的投票，<br>您获得了一次抽奖机会，<br>点击下方按钮即可参与抽奖！",
            buttonText: "抽奖",
            type: 'vote',
          }
          this.voteSuccess = true;
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        this.$toast(err)
      })
    },
    // !抽奖
    operation(type) {
      if(type == "vote") {
        const data = {
          actityShowId: this.actityShowId,
          actityId: this.worksParam.actityId
        }
        GetPrize(data).then(res => {
          if(res.status == 1) {
            if(res.data.isPrize) {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/win.png"),
                text: "恭喜您获得"+ res.data.prizeName,
                buttonText: "返回",
                type: 'cancel'
              }
            } else {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/nowin.png"),
                text: "<img src='" + require('@/assets/PC/keenMind/nowin-icon.png') +"' width='35%' style='margin-bottom: 10px'><br>很遗憾，您未中奖！",
                buttonText: "返回",
                type: 'cancel'
              }
            }
          }
        })
      } else if(type == "cancel") {
        this.voteSuccess = false
      }
    },
    // !去作品详情页
    goWorksDetail(id) {
      this.$router.push("/Home/worksDetail?id=" + id);
    },
    // !返回顶部
    scrollTop() {
      scrollTop()
    },
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
  },
  components: {
    swiper,
    breadcrumb
  }
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .ActivityPreview {
    .el-button--warning.is-disabled {background: #909399; border-color: #909399;}
    .crumbs { max-width: 1200px; margin: 0 auto; }
    .ActivityPreview-content { padding-bottom: 70px; }
    .worksList, .Page { max-width: 1200px; margin: 0 auto; }
    .works-Twitter { width: 385px; height: 240px; overflow: hidden; padding-right: 20px; }
    .works-item { padding: 30px; margin-bottom: 25px; box-shadow: 0 0 10px #ccc; border-radius: 5px; }
    .works-item-r { width: calc( 100% - 385px );  display: flex; flex-direction: column; justify-content: space-between; }
    .works-item-title { font-size: 19px; line-height: 26px; font-weight: bold; }
    .works-item-introduction {font-size: 15px; }

    .glance .van-icon-eye { font-size: 17px; margin-top: 2px;}
    .collect { margin-left: 20px; }
    .collect img { width: 16px; }
    .shade-content {width: 500px;}
    .shade-body { width: 100%; height: 100%; position: absolute; top: 0; left: 0; flex-direction: column;}
    .shade-text { line-height: 2; font-size: 16px; margin-bottom: 30px; }
    .shade-body-close { position: absolute; top: 15px; right: 15px; }
  }

  @media (max-width: 1200px) and (min-width: 751px) {
    .ActivityPreview {
      .worksList, .Page, .crumbs {  margin: 0 30px; }
    }
  }

  @media (max-width: 750px) {
    .ActivityPreview {
      .crumbs {max-width: 100%; padding-left: vw(40) }
      .ActivityPreview-content { padding: 0 }
      .works-Twitter { width: 100%; height: vw(440); padding-right: 0 }
      .works-item { margin: 0 vw(40) vw(40) vw(40); padding: 0; background: #F6F8FA; box-shadow: none }
      .works-item-r { width: 100%; padding: vw(30) }
      .works-item-title { font-size: vw(28) }
      .works-item-introduction {font-size: vw(24); margin-bottom: vw(40) }
      .glance .van-icon-eye { font-size: vw(34); margin-top: vw(2) }
      .collect { margin-left: vw(40) }
      .collect img { width: vw(32) }

      .shade-content {width: 90% } 
      .shade-text { line-height: 2; font-size: vw(32); margin-bottom: vw(60); padding-top: vw(30);}
    }
  }
</style>